import React from 'react';
import ReactDOM from 'react-dom/client';
import './asserts/styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';

const LazyApp = lazy(() => import("./App"))



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
    <Suspense fallback={<div>loading....</div>}>
      <LazyApp />
      </Suspense>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
